import React from 'react';
import Script from 'next/script';

import { siteCatalystAccount } from '~/global/global.constants';

export const PublicGlobals = () => {
	// TODO: Figure out if this is needed
	return (
		<>
			<Script
				strategy="afterInteractive"
			>
				{`
					window.adobeReportSuites = '${siteCatalystAccount}';
					window.dataLayer = window.dataLayer || {};
				`}
			</Script>
		</>
	);
};
