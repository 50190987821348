import React from 'react';
import Script from 'next/script';

import { newRelic } from '~/global/global.constants';

export const NewRelic = () => {
	const {
		accountId,
		agentId,
		applicationId,
		enabled = false,
		licenseKey,
		trustKey,
	} = newRelic || {};

	if (!enabled) {
		return null;
	}

	return (
		<>
			<Script
				strategy="afterInteractive"
			>
				{`
					window.NREUM = window.NREUM || {};
					window.NREUM.loader_config = {
						accountID: '${accountId}',
						trustKey: '${trustKey}',
						agentID: '${agentId}',
						licenseKey: '${licenseKey}',
						applicationID: '${applicationId}',
					};
					window.NREUM.info = {
						beacon: 'bam-cell.nr-data.net',
						errorBeacon: 'bam-cell.nr-data.net',
						licenseKey: '${licenseKey}',
						applicationID: '${applicationId}',
						sa: 1,
					};
				`}
			</Script>
			<Script
				strategy="afterInteractive"
				src="/scripts/lib/new-relic.js"
			/>
		</>
	);
};
