import {
	model, Model, idProp, prop,
} from 'mobx-keystone';
import { observable } from 'mobx';

import { modelNamespace } from '~/util/modelNamespace';
import { groupByFunction } from '~/util/groupBy';
import { IPersonalizationEvent, IPropositions, PERSONALIZATION_SCOPE } from '~/personalization/Personalization.types';

@model(`${modelNamespace.PERSONALIZATION}/PersonalizationDecisionsModel`)
export class PersonalizationDecisionsModel extends Model({
	id: idProp,
	decision: prop<IPropositions | undefined>().withSetter(),
}) {
	@observable.ref
	decisions!: IPersonalizationEvent;

	setCustomDecisions(decisions: IPersonalizationEvent) {
		this.decisions = decisions;
	}

	get groupDecisionsByScope(): { [key in PERSONALIZATION_SCOPE]?: IPropositions[] } {
		return groupByFunction(this.decisions.propositions, ({ scope }: { scope: PERSONALIZATION_SCOPE }) => scope);
	}
}
