import React, { useEffect } from 'react';
import Script from 'next/script';
import { observer } from 'mobx-react';

import { adobeLaunchGlobalUrlSDK } from '~/global/global.constants';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { IPersonalizationEvent } from '~/personalization/Personalization.types';
import { PersonalizationDecisionsModel } from '~/personalization/Models/PersonalizationDecisions.model';

export const AdobeTracking = observer(() => {
	const {
		personalizationStore = {},
	} = useGlobalContext();

	const handlePersonalizationDecision = (event: Event) => {
		const { detail } = event as CustomEvent<IPersonalizationEvent>;

		personalizationStore.setDecisionsModel(new PersonalizationDecisionsModel({}));
		personalizationStore.decisionsModel.setCustomDecisions(detail);
	};

	useEffect(() => {
		window.addEventListener('personalization', event => handlePersonalizationDecision(event));
		return () => {
			window.removeEventListener('personalization', handlePersonalizationDecision);
		};
	}, []);

	if (!adobeLaunchGlobalUrlSDK) {
		return null;
	}

	return (
		<>
			<Script
				strategy="afterInteractive"
				src={adobeLaunchGlobalUrlSDK}
			/>
		</>
	);
});
