import { observable, makeObservable } from 'mobx';

import { isOnServer, isEngage } from '~/global/global.constants';
import { BROKEN_UI, LAUNCH_PLACEHOLDER_TEXT, ifEmptyUsePlaceholderText } from '~/util/launchUtils';
import { sanitize } from '~/util/sanitizeString';
import { PERSONALIZATION_SCOPE } from '~/personalization/Personalization.types';

export class PageViewEventModel {
	pageTitle;

	pageType;

	errorStatusCode;

	personalizationScope;

	visitorEventModel;

	constructor(pageTitle, pageType, errorStatusCode, personalizationScope, visitorEventModel) {
		makeObservable(this, {
			visitorEventModel: observable,
		});
		this.pageTitle = pageTitle;
		this.pageType = pageType;
		this.errorStatusCode = errorStatusCode;
		this.personalizationScope = personalizationScope || [];
		this.visitorEventModel = visitorEventModel || {};
	}

	get pageId() {
		const { pathname = '' } = window.location;
		const normalizedPathname = pathname.replace(/^\//, '');

		if (pathname.includes('/search')) {
			return ':search';
		}

		if (normalizedPathname.length) {
			const normalizedPathnameWithColons = pathname.replace(/\/(?!$)/g, ':').replace(/\/|(\..+$)/g, '').toLowerCase();

			return normalizedPathnameWithColons;
		}

		return 'homepage';
	}

	get errorMessage() {
		return `${BROKEN_UI}: ${this.pageId}`;
	}

	get id() {
		if (!this.wasSuccessful) {
			return this.errorMessage;
		}
		return this.pageId;
	}

	get includeTargetScopeArray() {
		return !isEngage || this.targetScopeArray?.length;
	}

	get site() {
		return sanitize(window.location.hostname.replace('www.', ''));
	}

	get title() {
		if (!this.wasSuccessful) {
			return this.errorMessage;
		}
		return sanitize(this.pageTitle);
	}

	get type() {
		return ifEmptyUsePlaceholderText(this.pageType);
	}

	get url() {
		return window.location.href.substring(0, 255);
	}

	get url2() {
		const secondHalfOfUrl = window.location.href.substring(255);

		return secondHalfOfUrl.length ? secondHalfOfUrl : LAUNCH_PLACEHOLDER_TEXT;
	}

	get query() {
		const queryString = window.location.search.replace('?', '');
		return queryString.length ? queryString : LAUNCH_PLACEHOLDER_TEXT;
	}

	get wasSuccessful() {
		return typeof this.errorStatusCode === 'undefined' || !this.errorStatusCode;
	}

	get targetScopeArray() {
		if (!isEngage) {
			if (Array.isArray(this.personalizationScope)) {
				this.personalizationScope.push(PERSONALIZATION_SCOPE.MODAL);
				this.personalizationScope.push(PERSONALIZATION_SCOPE.PAGE_VIEW);
			} else {
				console.warn('PageViewEvent.tracking.model.js: personalizationScope is not an array', this.personalizationScope);
			}
		}
		return this.personalizationScope;
	}

	get trackingJson() {
		return {
			'event': 'pageview',
			'successful': this.wasSuccessful,
			'page': {
				'id': this.id,
				'title': this.title,
				'type': this.type,
				'url': this.url,
				'url2': this.url2,
				'query': this.query,
			},
			...this.visitorEventModel.trackingJson,
			...(this.includeTargetScopeArray && {
				'target': {
					'scope': this.targetScopeArray,
				},
			}),
		};
	}

	trackPageView() {
		const json = this.trackingJson;

		if (!isOnServer) {
			if (!window.upcomingPagesEventDataLayer) {
				window.upcomingPagesEventDataLayer = [];
			}

			if (!window.eventDataLayer) {
				window.eventDataLayer = [];
			}
			// console.info(`routeChangeStarted? ${window.routeChangeStarted} `, json);

			if (!window.routeChangeStarted) {
				const pageViewExists = window.eventDataLayer.some(pageView => pageView.event === json.event);
				if (!pageViewExists) {
					window.eventDataLayer.push(json);
				}
			} else {
				window.upcomingPagesEventDataLayer.push(json);
			}
		}
	}
}
